<template>
  <b-modal id="modal-create-lease-type" ref="modal-create-lease-type" centered="centered" :title="(leaseType.id ? 'Modifier': 'Ajouter') + ` un type de bail`" @ok="create">
      <div v-if="isCreatingLeaseType">
          <div class="text-center">
              <div class="spinner-border text-primary" role="status"><span class="sr-only">Chargement...</span></div><br />Chargement des détails...
          </div>
      </div>
      <validation-observer v-else ref="formCreateLeaseType">
          <b-row>
              <b-col cols="12" class="d-flex mb-1">
                  <b-form-group label="Libellé *" label-for="Libellé" style="flex:1">
                      <validation-provider #default="{ errors }" name="Libellé" rules="required">
                      <b-form-input
                          id="label"
                          v-model="leaseType.label"
                          :state="errors.length > 0 ? false : null"
                          class="not-autocomplete"
                          autocomplete="nope" aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
              </b-col>
          </b-row>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
          <div class="w-100 mx-0">
              <!-- <b-button class="float-left" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="leaseType.id" variant="outline-danger" @click="archiveLeaseTypeLocal(leaseType.id, leaseType.label)"><feather-icon icon="ArchiveIcon" /></b-button> -->
              <b-button class="float-right" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="ok()">{{leaseType.id ? 'Modifier': 'Ajouter'}}</b-button>
              <b-button class="float-right mr-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="cancel()">Annuler</b-button>
          </div>
      </template>
  </b-modal>
  </template>
  
  <script>
  import {
    ValidationProvider,
    ValidationObserver,
    defineRule,
    configure,
    localize,
  } from "vee-validate";
  import VSwatches from "vue-swatches";
  import { required, url, email } from "@validations";
  import { mapGetters, mapActions } from "vuex";
  import Ripple from "vue-ripple-directive";
  import "vue-swatches/dist/vue-swatches.css";
  configure({
    // Generates an English message locale generator
    generateMessage: localize("fr", {
      messages: {
        url: "Ce champ doit être une URL",
        required: "Ce champ est requis",
        email: "Ce champ doit être un email",
      },
    }),
  });
  localize("fr");
  
  export default {
    data() {
      return {
        required,
        url,
        email,
        leaseType: {
          label: "",
          workspaceId: this.$store.getters.workspaceSelected.id,
        },
      };
    },
    methods: {
      popupCreateLeaseTypeEvent(id){
        if(id>0){
          this.fetchLeaseType(id).then(res => {
            this.leaseType = res
          })
        }else this.initializeForm()
      },
      initializeForm() {
        this.leaseType = {
          label: "",
          workspaceId: this.$store.getters.workspaceSelected.id,
        };
      },
      create(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.formCreateLeaseType.validate().then((success1) => {
          if (success1) {
            if(this.leaseType.id) this.updateLeaseType(this.leaseType)
            else this.createLeaseType(this.leaseType);
            this.$nextTick(() => {
              this.$refs["modal-create-lease-type"].toggle("#toggle-btn");
            });
          }
        });
      },
      archiveLeaseTypeLocal (id, label) {
        let _this = this
        this.$bvModal
          .msgBoxConfirm('Vous pourrez à tout moment restaurer ce type de bail en consultant la liste de vos archives.', {
            title:
              'Êtes-vous sûr de vouloir archiver le type de bail "' + label + '" ?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Archiver',
            cancelTitle: 'Annuler',
            cancelVariant: 'outline-primary',
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.archiveLeaseType(id);
              this.$nextTick(() => {
                this.$bvModal.hide('modal-create-lease-type')
              })
            }
            
          })
      },
      ...mapActions(["createLeaseType", "updateLeaseType", "fetchLeaseType","archiveLeaseType"]),
    },
    computed: {
      ...mapGetters(["isCreatingLeaseType"]),
    },
    
    components: {
      ValidationProvider,
      ValidationObserver,
      VSwatches,
    },
    directives: {
      Ripple,
    },
  };
  </script>